/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
require('./src/styles/global.css')
// You can delete this file if you're not using it
exports.onClientEntry = () => {
    window.addEventListener('load', () => {
      document.body.className = document.body.className.replace(/\bno-js\b/, '');
    });
}